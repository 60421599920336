export const localStorageMixin = {

  methods: {
    saveLocalStorage (key, value) {
      localStorage.setItem(key, value)
    },
    loadLocalStorage (key, defaultValue = false) {
      let itemValue = defaultValue

      if (localStorage.getItem(key) !== null) {
        itemValue = (localStorage.getItem(key))
      } else {
        this.saveLocalStorage(key, defaultValue)
      }
      return itemValue
    },
    eraseLocalStoragePattern (pattern) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key.startsWith(pattern)) {
          localStorage.removeItem(key)
        }
      }
    }
  }
}
